<template>
  <div class="mb-6" v-if="documents && documents.length > 0">
    <div v-if="loading">
      <b-skeleton height="72px" :animated="true"></b-skeleton>
      <b-skeleton height="72px" :animated="true"></b-skeleton>
      <b-skeleton height="72px" :animated="true"></b-skeleton>
    </div>

    <div v-else>
      <p class="has-text-weight-bold">
        {{ $t("af:application.flow.document_prompt.download.title") }}
      </p>

      <div
        v-for="document in documents"
        :key="document.id"
        class="is-flex is-align-items-center document-tile mx-0 my-2"
      >
        <div>
          <b-icon
            icon-pack="far"
            icon="vote-yea"
            type="is-blue"
            size="is-medium"
            class="mr-2"
          ></b-icon>
        </div>

        <div class="has-text-blue document-tile-filename">
          {{ trimFileName(document.displayName) }}
        </div>

        <div class="ml-auto is-flex-shrink-0">
          <span
            id="flowDocumentButtonDownload"
            class="cursor-pointer"
            @click="downloadFile(document)"
          >
            <b-icon
              icon-pack="fa"
              icon="download"
              type="is-blue"
              class="mr-2"
            ></b-icon>
          </span>
          <span
            id="flowDocumentButtonDelete"
            class="cursor-pointer"
            @click="deleteFile(document)"
          >
            <b-icon
              icon-pack="fa"
              icon="trash"
              type="is-blue"
              class="mr-2"
            ></b-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FileSaver from "file-saver";

export default {
  name: "ApplicationFlowDocumentPromptDownloader",
  props: {
    promptId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getFile: "drawer/getFile",
    }),
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
  },
  data() {
    return {
      isModalOpen: false,
      documents: [],
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.loadDocuments();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      download: "drawer/download",
      deleteFileFromApplication: "loanApplication/deleteFile",
      deleteFileFromMortgageApplication: "mortgageApplication/deleteFile",
      downloadMetaData: "drawer/getCustomerMetaData",
    }),
    trimFileName(filename) {
      if (
        (filename.length < 25 && window.innerWidth < 768) ||
        (filename.length < 50 && window.innerWidth >= 768)
      ) {
        return filename;
      }
      return filename.slice(0, window.innerWidth < 768 ? 25 : 50) + "...";
    },
    async loadDocuments() {
      this.documents = [];
      let documents = await this.$store.dispatch(
        this.$route.meta.mortgage
          ? "mortgageApplication/fetchPreviousDocuments"
          : "loanApplication/fetchPreviousDocuments",
        this.promptId
      );
      if (documents) {
        for (const index in documents) {
          let documentId = documents[index].documentId;
          if (!this.documents.find((document) => document.id === documentId)) {
            let metaData = await this.downloadMetaData(documentId);
            this.documents.push(metaData);
          }
        }
      }
    },
    async downloadFile(document) {
      await this.download(document.id);
      let blob = this.getFile(document.id);
      FileSaver.saveAs(blob, document.fileName);
    },
    async deleteFile(document) {
      if (this.isMortgage) {
        await this.deleteFileFromMortgageApplication(document.id);
      } else {
        await this.deleteFileFromApplication(document.id);
      }
      await this.loadDocuments();
      this.$emit("documentDeleteFinish", this.documents.length > 0);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.document-tile {
  background: $grey-2;
  border-radius: 8px;
  padding: 1.5rem;
}

.document-tile-filename {
  padding-top: 0.5rem;
}

.mbh {
  .has-text-blue {
    color: #8f1c6b !important;
  }

  .document-tile {
    background: white;
    border-radius: 24px;
  }
}
</style>
